<template>
  <div id="app">
    <math-nav />
    <router-view />
    <!-- 底部 -->
    <foot />
  </div>
</template>

<script>
import mathNav from "base/nav";
import foot from "base/foot";
export default {
  components: {
    mathNav,
    foot
  },
};
</script>

<style>
#app {
  padding-top: 64px;
}
#app > section {
  min-height: calc(100vh - 160px);
  padding-bottom: 32px;
}
@media (max-width: 992px) {
  #app > section {
    min-height: calc(100vh - 208px);
  }
}
</style>
