<template>
  <nav class="com-nav flex-align-center">
    <router-link class="navbar-brand" to="/">
      <img src="@/static/img/logo/nav.png" height="24" />
    </router-link>
    <main class="flex-end-center">
      <template v-if="isNotHome||scrollOverBanner">
        <div class="flex-center" >
          <nav-search class="hidden-md"></nav-search>
        </div>
        <div class="search-mobile show-md" @click.stop="isMSearchShow = true">
          <img src="@/static/img/icon/search.png" width="18" />
          <nav-search v-show="isMSearchShow" @hideSearch="isMSearchShow=false"></nav-search>
        </div>
      </template>
      <img
        src="@/static/img/icon/menu.png"
        height="32"
        class="show-xs"
        @click="collapseShow = true"
      />
      <div :class="['collapse', { open: collapseShow }]">
        <div class="collapse-close show-xs" @click.stop="colHide">×</div>
        <ul class="flex-align-center tog-nav">
          <li @click.stop="colHide" class="show-xs">
            <router-link to="/">Home</router-link>
          </li>
          <li class="dropdown">
            <a
              href="javascript:;"
              class="dropdown-toggle"
              @click.stop="
                chainShow = !chainShow;
                tokenShow = false;
                langShow = false;
              "
            >
              {{ MC.name }}
            </a>
            <ul class="dropdown-menu" v-show="chainShow">
              <li
                @click.stop="colHide"
                v-for="item in MC.chainList"
                :key="item"
              >
                <a target="_blank" rel="noopener noreferrer" :href="MC[item].explorer">{{ MC[item].name }}</a>
              </li>
            </ul>
          </li>
          <li class="dropdown">
            <a
              href="javascript:;"
              class="dropdown-toggle"
              @click.stop="
                tokenShow = !tokenShow;
                langShow = false;
                chainShow = false;
              "
            >
              Token
            </a>
            <ul class="dropdown-menu" v-show="tokenShow">
              <li @click.stop="colHide">
                <router-link to="/createToken">{{
                  $t("CreateToken")
                }}</router-link>
              </li>
              <li @click.stop="colHide">
                <a href="https://m.maiziqianbao.net/submit/token?type=" target="_blank" rel="noopener noreferrer">{{ $t("SubmitToken") }}</a>
              </li>
              <li @click.stop="colHide">
                <router-link to="/tokens">{{ $t("TokenList") }}</router-link>
              </li>
            </ul>
          </li>
          <!-- <li>
            <a
              :href="MC.swapLink"
              target="_blank"
              rel="noopener noreferrer"
              >{{ $t("Swap") }}</a
            >
          </li> -->
          <li>
            <a
              :href="MC.walletLink"
              target="_blank"
              rel="noopener noreferrer"
              >{{ $t("Wallet") }}</a
            >
          </li>
          <li v-if="MC.ID == 0">
            <router-link to="/faucet">{{ $t("faucet") }}</router-link>
          </li>
          <li class="hidden-xs" style="color: rgba(255, 255, 255, 0.25)">|</li>
          <li class="dropdown">
            <a
              href="javascript:;"
              class="dropdown-toggle"
              @click.stop="
                langShow = !langShow;
                tokenShow = false;
                chainShow = false;
              "
            >
              {{ $t("lang") }}
            </a>
            <ul class="dropdown-menu" v-show="langShow">
              <li @click.stop="changeLang('en')">
                <a href="javascript:;">English</a>
              </li>
              <li @click.stop="changeLang('cn')">
                <a href="javascript:;">中文</a>
              </li>
              <li @click.stop="changeLang('ko')">
                <a href="javascript:;">한국어</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </main>
  </nav>
</template>
<script>
import navSearch from "base/search";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      langShow: false,
      tokenShow: false,
      chainShow: false,
      collapseShow: false,
      isMSearchShow: false,
      isNotHome: false,
      scrollOverBanner: false,
    };
  },
  mounted() {
    this.getIsHome();
    document.documentElement.onclick = () => {
      this.isMSearchShow = false;
      this.dropHide();
    };
  },
  methods: {
    ...mapActions(["setLang"]),
    toggleChain(chain) {
      this.colHide();
      this.$router.push({
        path: "/" + chain,
      });
    },
    dropHide() {
      this.chainShow = false;
      this.langShow = false;
      this.tokenShow = false;
    },
    srcollFunc() {
      var scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;

      if (scrollTop >= 200) {
        this.scrollOverBanner = true;
      } else {
        this.scrollOverBanner = false;
      }
    },
    getIsHome() {
      if (this.$route.path=='/') {
        this.isNotHome = false;
        this.srcollFunc();
      } else {
        this.isNotHome = true;
      }
      window.onscroll = () => {
        if (this.$route.path=='/') {
          this.srcollFunc();
        }
      };
    },
    changeLang(lang) {
      this.setLang(lang);
      this.colHide();
    },
    colHide() {
      this.collapseShow = false;
      this.dropHide();
    },
  },
  watch: {
    $route() {
      this.getIsHome();
    },
  },
  components: {
    navSearch,
  },
};
</script>
<style scoped>
.com-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--navBg);
  padding: 16px 12px 16px 24px;
  z-index: 1000;
}
.com-nav main,.com-nav .flex-center{
  flex: 1;
}
.navbar-brand {
  padding: 4px 12px 4px 0;
}
.navbar-brand img {
  display: block;
}
.com-nav li {
  padding: 4px 12px;
}
.com-nav li a {
  color: #fff;
  font-size: 14px;
  line-height: 16px;
  display: block;
  white-space: nowrap;
}
.collapse-close {
  font-size: 28px;
  padding: 14px 20px;
  color: #fff;
  text-align: right;
}
.dropdown-menu {
  margin-top: 10px;
  right: 50%;
  transform: translateX(50%);
}
.dropdown-menu li {
  padding: 6px 12px;
}
.dropdown-menu li a {
  color: #000;
}
.basic-group {
  max-width: 350px;
  height: 32px;
}
.search-mobile img {
  cursor: pointer;
}
.search-mobile .basic-group {
  position: absolute;
  left: 16px;
  right: 16px;
  top: 16px;
  margin: 0;
  width: auto;
  max-width: unset;
  z-index: 10;
}

/*小于768px屏幕 手机 xs*/
@media (max-width: 768px) {
  .search-mobile {
    padding-right:12px;
  }
  .collapse {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--navBg);
    text-align: center;
    z-index: 1040;
    display: none;
  }
  .open {
    display: block;
  }
  .tog-nav {
    display: block;
  }
  .tog-nav li a {
    display: inline-block;
    font-size: 20px;
    line-height: 1;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .dropdown-menu {
    margin-top: -10px;
  }
  .dropdown-menu li a {
    padding: 4px 0;
  }
}
@media (max-width: 360px) {
  .navbar-brand img {
    height: 20px;
  }
}
</style>

