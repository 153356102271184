let mainnet = {
  ID: 1,
  l1chainID: 39,
  l2chainID: 1139,
  name: "MathChain",
  explorer: "https://explorer.mathchain.org",
  walletLink: "https://mathwallet.org/evm/#/mathchainl2",
  swapLink: "https://swap.mathchain.org/",
  ws: "wss://mathchain-us.maiziqianbao.net/ws", //wss://mathchain-asia.maiziqianbao.net/ws
  rpc: "https://mathchain-us.maiziqianbao.net/rpc", //https://mathchain-asia.maiziqianbao.net/rpc
};

let galois = {
  ID: 0,
  l2chainID: 1140,
  name: "Galois",
  explorer: "https://explorer-galois.mathchain.org",
  walletLink: "https://mathwallet.org/web/galoisl2",
  swapLink: "https://swap-galois.mathchain.org/",
  ws: "wss://galois.maiziqianbao.net/ws",
  rpc: "https://galois.maiziqianbao.net/rpc",
  ss58Format: 40,
};

let currentChain = galois;

export default {
  chainList: ["mainnet","galois"],
  mainnet,
  galois,
  ...currentChain,
  domain : "/api/",
  tokenDecimals: 18,
  tokenSymbol : "MATH",
  types:{
    Address: "MultiAddress",
    LookupSource: "MultiAddress",
    Account: {
      nonce: "U256",
      balance: "U256",
    },
    Transaction: {
      nonce: "U256",
      action: "String",
      gas_price: "u64",
      gas_limit: "u64",
      value: "U256",
      input: "Vec<u8>",
      signature: "Signature",
    },
    Signature: {
      v: "u64",
      r: "H256",
      s: "H256",
    },
    ExitReason: "U256",
    AccountServiceEnum: {
      _enum: {
        Nickname: "String",
        Ethereum: "H160",
      },
    },
    MultiAddressDetails: {
      nickname: "AccountServiceEnum",
      ethereum: "AccountServiceEnum",
    },
    Nickname: "String",
    Ethereum: "H160",
    TransferAmountInfo: {
      date: "u64",
      daily_info: "Balance",
      monthly_info: "Balance",
      yearly_info: "Balance",
    },
    AccountLimit: {
      daily_limit: "Balance",
      monthly_limit: "Balance",
      yearly_limit: "Balance",
    },
  },
}