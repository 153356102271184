<template>
  <div class="basic-group">
    <input
      @focus="searchFocus"
      @keydown.enter="dbSearchInfo"
      v-model="search_info"
      type="text"
      :placeholder="$t('search_place')"
      autocomplete="off"
    />
    <button @click.stop="dbSearchInfo">
      <img src="@/static/img/icon/search_pink.png" width="16" />
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      search_info: "",
    };
  },
  created(){
    this.dbSearchInfo = this.lodash.debounce(this.searchInfo, 500);
  },
  methods: {
    searchFocus() {
      let posT =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      window.scrollTo(0, posT);
    },
    searchInfo() {
      let value = this.search_info.trim();
      if (!value) {
        return false;
      }
      // 0x306bb8081c7dd356ea951795ce4072e6e4bfdc32
      // 0xbfb7269e174494cdfda3d9cbc11e162c185d308690de2075a9c393d07333b03f
      // 54UMsWbdcewaAc6yAY8TxwTDnT7EQPJBjtya2W2dhhG3KWJn

      // 判断是否为16进制的字符串
      let info = value.indexOf("0x") == 0 ? value.slice(2) : value;

      if (isNaN(info)) {
        if (info.includes("-") || info.length >= 64) {
          //交易 hash | ID
          value = info.includes("-") ? info : "0x" + info;
          this.axios
            .get(this.domain + "v1/extrinsic/" + value)
            .then((res) => {
              if (res.data.data) {
                // 若为hash值则要跳转到 判断是否为EVM hash的页面
                // let path = value.length == 66 ? "/searchResult/" : "/extrinsic/";
                this.$router.push({
                  path: "/extrinsic/" + value,
                });
              } else {
                this.getEvmTx(value);
              }
            })
            .catch(() => {
              this.getEvmTx(value);
            });
        } else if (value.indexOf("0x") == 0) {
          //evm 账户
          this.getL2Account(value);
        } else {
          //账户
          this.getL1Account(info);
        }
      } else {
        //block height
        this.getBlockInfo(info);
      }
    },
    getEvmTx(value) {
      this.axios
        .get(this.domain + "evm/transaction/hash/" + value)
        .then((res) => {
          if (res.data) {
            this.$emit('hideSearch');
            this.$router.push({
              path: "/evmtx/" + value,
            });
          }
        })
        .catch(() => {
          this.$toast(this.$t("search_fail"));
          this.search_info = "";
        });
    },
    getL1Account(info) {
      this.user.getBalance(info).then(res=>{
        if(res){
          this.$emit('hideSearch');
          this.$router.push({
            path: "/account/" + info,
          });
        }else {
          this.$toast(this.$t("search_fail"));
        }
        this.search_info = "";
      }).catch(() => {
        this.$toast(this.$t("invalidAddress"));
        this.search_info = "";
      });
    },
    getL2Account(value) {
      this.getEVMBalance(value).then(res=>{
        if (res) {
          this.$emit('hideSearch');
          this.$router.push({
            path: "/evm/" + value,
          });
        } else {
          this.$toast(this.$t("search_fail"));
        }
        this.search_info = "";
      }).catch(() => {
          this.$toast(this.$t("invalidAddress"));
          this.search_info = "";
        });
    },
    getBlockInfo(info) {
      this.axios
        .get(this.domain + "v1/block/" + info + "?include=extrinsics")
        .then((res) => {
          if (res.data.data) {
            this.$emit('hideSearch');
            this.$router.push({
              path: "/block/" + info,
            });
          } else {
            this.$toast(this.$t("search_fail"));
          }
          this.search_info = "";
        })
        .catch(() => {
          this.$toast(this.$t("search_fail"));
          this.search_info = "";
        });
    },
  },
};
</script>
<style scoped>
.basic-group input {
  padding: 8px 52px 8px 12px;
  font-size: 12px;
  line-height: 16px;
}
.basic-group button {
  width: 40px;
  padding: 4px 0;
}
</style>
