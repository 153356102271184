import BigNumber from 'bignumber.js'
class Util {
  // 时间戳转换UTC日期 （秒）
  timestampToDate( timestamp ) {
    if ((timestamp + '').length == 10) {
      timestamp = timestamp * 1000
    }
    var date = new Date(timestamp); //时间戳为10位需*1000
    var Y = date.getUTCFullYear() + '-';
    var M = (date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1) + '-';
    var D = (date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()) + ' ';
    var h = (date.getUTCHours() < 10 ? '0' + date.getUTCHours() : date.getUTCHours()) + ':';
    var m = (date.getUTCMinutes() < 10 ? '0' + date.getUTCMinutes() : date.getUTCMinutes()) + ':';
    var s = date.getUTCSeconds() < 10 ? '0' + date.getUTCSeconds() : date.getUTCSeconds();
    return Y + M + D + h + m + s +' (+UTC)';
  }

  stringFormat(str) {
    var res = '';
    if (String(str) != "[object Object]") {
      res = String(str).slice(0, 6) + ' ... ' + str.slice(-8)
    }
    return res
  }

  /**
   * cookie操作
   */
  setCookie(name, value, options) {
    options = options || {};
    if (value === null) {
      value = '';
      options.expires = -1;
    }
    var expires = '';
    if (options.expires && (typeof options.expires == 'number' || options.expires.toUTCString)) {
      var date;
      if (typeof options.expires == 'number') {
        date = new Date();
        date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000));
      } else {
        date = options.expires;
      }
      expires = '; expires=' + date.toUTCString(); // use expires attribute, max-age is not supported by IE
    }
    var path = options.path ? '; path=' + options.path : '';
    var domain = options.domain ? '; domain=' + options.domain : '';
    var s = [cookie, expires, path, domain, secure].join('');
    var secure = options.secure ? '; secure' : '';
    var c = [name, '=', encodeURIComponent(value)].join('');
    var cookie = [c, expires, path, domain, secure].join('')
    document.cookie = cookie;
  }

  getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie != '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) == (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  // 数字千分位，保留n位小数格式化
  addCommas(number, n = 2) {
    if (typeof number !== "number") {
      number = Number(number);
    }
    let divNumber = new BigNumber(number).toFormat(n-0,1);
    return divNumber;
  }

  formatNumber( number, decimals ) {
    if (typeof number !== "number") {
      number = Number(number);
    }
    let divNumber = new BigNumber(number)
      .div(Math.pow(10, decimals))
      .toFormat();
    return divNumber;
  }

  BN(num) {
    return new BigNumber( num );
  }

  //Map 转 JSON
  strMapToJson(strMap) {
    let obj = Object.create(null);
    for (let [k, v] of strMap) {
      obj[k] = v;
    }
    return JSON.stringify(obj);
  }

  //Map 转 obj
  strMapToObj(strMap) {
    return JSON.parse(this.strMapToJson(strMap));
  }

  formatStrByDot( str,start=7,end=5 ) {
    var reg = new RegExp( "(.{" + start + "})(.+)(.{" + end + "})", "g" );
    if (String(str).length > 12) {
      return String(str).replace(reg, "$1...$3")
    }
    return str;
  }
}

let util = new Util;

export default util;