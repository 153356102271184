import Vue from 'vue'
import App from './App'
import router from './router'
import axios from 'axios'
import i18n from 'static/js/i18n'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import lodash from "lodash"
import webUtil from 'static/js/util'
import Toast from './base/Toast'
import ba from 'vue-ba'
import MC from 'static/js/config'
import user from 'static/js/user'
import 'static/css/common.css'
Vue.use(VueClipboard)
Vue.use(Toast)
Vue.config.productionTip = false

/*注册到vue原型上*/
Vue.prototype.axios = axios;
Vue.prototype.webUtil = webUtil;
Vue.prototype.lodash = lodash;
Vue.prototype.user = new user(MC.ws,MC.types);


// 混入方法
import { mapGetters } from 'vuex'
Vue.mixin( {
  data() {
    return {
      marketApi: process.env.NODE_ENV == 'development' ? '/market/' : "https://market.maiziqianbao.net/api/",
      MC,
      tokenDecimals:MC.tokenDecimals,
      tokenSymbol: MC.tokenSymbol,
      domain: MC.domain
    }
  },
  computed: {
    ...mapGetters({langLocale: "lang"}),
  },
  methods: {
    copyAction(copyInfo) {
      this.$copyText(copyInfo).then(e=>{
        this.$toast(this.$t('copiedSuccess'))
      }, e=>{
        this.$toast('Copy Failed');
      })
    },
    async getEVMBalance(account){
      return await this.axios.post(MC.rpc, {
        jsonrpc:"2.0",
        method:"eth_getBalance",
        params:[
          account,
          "latest"
        ],
        id:1
      }).then(res=>{
        if ( res.data ) {
          return res.data.result;
        }
      })
    },
    catchERR(err){
      if(err){
        this.$toast(err,3000)
      }else{
        this.$toast(this.$t('noMathExtension'),3000)
      }
    }
  }
});

// 百度统计
Vue.use(ba, "073169109183cd68fd5e6fe7d2317cbf");
Vue.use( ba, { siteId: "073169109183cd68fd5e6fe7d2317cbf" } );

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')