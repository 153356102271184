module.exports = {
  lang: "中文",
  home: "首页",
  blog: "博客",
  blogs: "博客",
  search_place: "搜索区块高度/哈希/账户地址",
  price: "价格",
  height: "区块高度",
  block: "区块",
  blocks: "区块",
  transfers: "转账",
  extrinsics: "交易",
  extrinsic: "交易记录",
  view_all: "查看全部",
  txs: "交易数量",
  address: "地址",
  type: "类型",
  amount: "数量",
  blockHash: "区块哈希",
  blockTime: "出块时间",
  totalExtrinsics: "交易数量",
  status: "交易状态",
  transaction_information: "交易详情",
  noInformation: "暂无数据",
  assets: "资产",
  search_fail: "找不到对应的交易、区块或账号",
  module: "模块",
  signedExtrinsics: "签名交易",
  signature: "签名",
  extrinsicsIndex: "交易索引",
  balance: "余额",
  freeBalance: "可用余额",
  reservedBalance: "预留余额",
  created_block: "创建块高",
  updated_block: "更新块高",
  nonce: "交易总数",
  destination: "接收地址",
  activeAccount: "活跃账户",
  accounts: "账户",
  active: "Active",
  projects: "项目",
  from: '来自',
  to: '至',
  event: '触发事件',
  eventID: '事件ID',
  copiedSuccess: "复制成功",
  invalidAddress: "无效地址",
  faucet: "水龙头",
  faucet_address: "账户地址",
  call: "调用",
  sender: "发送账户",
  fee: "手续费",
  result: "结果",
  parameters: "参数",
  extrinsicHash: "交易哈希",
  moreInfo: "更多信息",
  overview: "概况",
  locked: "已锁定",
  time: "时间",
  hash: "哈希",
  action: "操作",
  value: "数量",
  valuePrice: "价值",
  events: "事件",
  extrinsicID: "交易 ID",
  EVMHash: "L2 哈希",
  GasLimit: "燃料限制",
  GasTransaction: "交易燃料费用",
  GasPrice: "燃料价格",
  InputData: "数据输入",
  searchResult: "搜索结果",
  transactions: "交易",
  send: "发送",
  inputAddressFirst: "请输入账户地址",
  backToHome: "返回首页",
  TotalSupply: "总供应量",
  HoldersCount: "持有人",
  Holders: "持有人",
  SearchToken: "搜索 Token 名称",
  Decimals: "小数点",
  ContractCreator: "合约创建者",
  TokenTracker: "Token 搜索器",
  Contract: "合约",
  Code: "代码",
  ReadContract: "阅读合约",
  WriteContract: "编写合约",
  ContractVerified: "合约源代码已验证 ",
  ExactMatch:"(完全匹配)",
  ContractName: "合约名称",
  CompilerVersion: "编译器版本",
  ContractSourceCode: "合约代码",
  AreYouCreator: "你是合约的创建者吗?",
  VerifyPublishCode:"请在这里验证并发布合约源代码",
  VerifyPublishTitle: "验证并发布合约源代码",
  EnterContractAddress:"输入验证的合约地址",
  SelectCompilerType: "选择编译器类型",
  SelectCompilerVersion: "选择编译器版本",
  SelectLicenseType: "选择开源许可证类型",
  EnterSolidityCode: "在下面输入 Solidity 合约代码",
  VerifyPublish: "验证并发布",
  PleaseSelect: "请选择",
  ContractABI: "合约 ABI",
  ReadContractInformation: "阅读合约相关信息",
  Pending:"进行中...",
  ConnectToWeb3:"连接 Web3",
  ConnectedWeb3:"已连接 Web3",
  Query:"查询",
  Write: "编写",
  noMathExtension: "请先连接麦子钱包扩展程序",
  ContractCreationCode:"合约创建代码",
  totalCirculating: "流通总量",
  ofTotalSupply: "占发行总量的",
  unMined: "待释放",
  TokenList: "Token 列表",
  CreateToken: "创建 Token",
  TokenLogo: "Token Logo",
  TokenSymbol: "Token Symbol",
  TokenName: "Token Name",
  TotalSupply: "总供应量",
  OwnerAddress:"Owner Address",
  Create: "创建",
  Swap: "兑换",
  Wallet: "网页钱包",
  Copy: "复制",
  ViewCode: "查看代码",
  Decode: "解码",
  SubmitSuccess:"提交成功",
  CreateSuccess: "创建成功",
  Error: "失败",
  Enter: "请输入",
  ConnectWallet: "连接钱包",
  Download: "下载",
  SubmitToken: "提交 Token",
  Mat20TokenTxns: "Mat20 Token 交易",
  FilterByHolder: "按 Token 持有者筛选",
  ClearFilterAddress: "清除地址筛选",
}