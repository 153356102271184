module.exports = {
  lang: "한국어",
  home: "홈페이지",
  blog: "블로그",
  blogs: "블로그",
  search_place: "블록 하이트/하시/계정 검색",
  price: "가격",
  height: "블록 하이트",
  block: "블록",
  blocks: "블록",
  transfers: "이체",
  extrinsics: "거래",
  extrinsic: "거래",
  view_all: "전체 조회",
  txs: "거래 수량",
  address: "주소",
  type: "종류",
  amount: "수량",
  blockHash: "블록 하시",
  blockTime: "블록 타임",
  totalExtrinsics: "거래 수량",
  status: "거래 상태",
  transaction_information: "거래 내역",
  noInformation: "데이터 없음",
  assets: "자산",
  search_fail: "해당 거래, 블록 또는 계정을 찾을 수 없음",
  module: "모듈",
  signedExtrinsics: "거래 사인",
  signature: "사인",
  extrinsicsIndex: "거래 색인",
  balance: "총잔액",
  freeBalance: "잔액",
  reservedBalance: "예치잔액",
  created_block: "Created at block",
  updated_block: "Updated at block",
  nonce: "거래 총량",
  destination: "수취 주소",
  activeAccount: "액티브 계정",
  accounts: "계정",
  active: "Active",
  projects: "Projects",
  from: "발송자",
  to: "목적지",
  event: '시작된 이벤트',
  eventID: '이벤트 아이디',
  copiedSuccess: "Copied Successfully",
  invalidAddress: "Invalid address",
  faucet: "Faucet",
  faucet_address: "주소",
  call: "Call",
  sender: "Sender",
  fee: "Fee",
  result: "Result",
  parameters: "Parameters",
  extrinsicHash: "거래 하시",
  moreInfo: "More Info",
  overview: "Overview",
  locked: "Locked",
  time: "거래 시간",
  hash: "Hash",
  action: "Action",
  value: "Value",
  valuePrice: "Value",
  events: "Events",
  extrinsicID: "Extrinsic ID",
  EVMHash: "L2 Hash",
  GasLimit: "Gas Limit",
  GasTransaction: "Gas Used by Transaction",
  GasPrice: "Gas Price",
  InputData: "Input Data",
  searchResult: "Search Result",
  transactions: "Transactions",
  send: "Send",
  inputAddressFirst: "Input Address First",
  backToHome: "메인화면으로 가기",
  TotalSupply: "Total Supply",
  HoldersCount: "Holders Count",
  Holders: "Holders",
  SearchToken: "Search for Token Name or Symbol",
  Decimals: "Decimals",
  ContractCreator: "Contract Creator",
  TokenTracker: "Token Tracker",
  Contract: "Contract",
  Code: "Code",
  ReadContract: "Read Contrat",
  WriteContract: "Write Contrat",
  ContractVerified: "Contract Source Code Verified ",
  ExactMatch:"(Exact Match)",
  ContractName: "Contract Name",
  CompilerVersion: "Compiler Version",
  ContractSourceCode: "Contract Source Code",
  AreYouCreator: "Are you the contract creator?",
  VerifyPublishCode:"Verify and Publish your contract source code",
  VerifyPublishTitle: "Verify & Publish Contract Source Code",
  EnterContractAddress:"Please enter the Contract Address you would like to verify",
  SelectCompilerType: "Please select Compiler Type",
  SelectCompilerVersion: "Please select Compiler Version",
  SelectLicenseType: "Please select Open Source License Type",
  EnterSolidityCode: "Enter the Solidity Contract Code below",
  VerifyPublish: "Verify & Publish",
  PleaseSelect: "Please Select",
  ContractABI:"Contract ABI",
  ReadContractInformation: "Read Contract Information",
  Pending:"Pending...",
  ConnectToWeb3:"Connect to Web3",
  ConnectedWeb3:"Connected - Web3",
  Query:"Query",
  Write: "Write",
  noMathExtension: "Math Wallet 브라우저를 접속하세요",
  ContractCreationCode: "Contract Creation Code",
  totalCirculating: "유통 총량",
  ofTotalSupply: "발행 총량의",
  unMined: "UnMined",
  TokenList: "Token List",
  CreateToken: "Create Token",
  TokenLogo: "Token Logo",
  TokenSymbol: "Token Symbol",
  TokenName: "Token Name",
  TotalSupply: "Total Supply",
  OwnerAddress:"Owner Address",
  Create: "Create",
  Swap: "Swap",
  Wallet: "Wallet",
  Copy: "Copy",
  ViewCode: "View Code",
  Decode: "Decode",
  SubmitSuccess: "Submitted successfully",
  CreateSuccess: "Created successfully",
  Error: "Error",
  Enter: "Please Enter",
  ConnectWallet: "Connect Wallet",
  Download: "Download",
  SubmitToken: "Submit Token",
  Mat20TokenTxns: "Mat20 Token Txns",
  FilterByHolder: "Filtered By Token Holder",
  ClearFilterAddress: "Clear Filter Address",
}