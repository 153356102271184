module.exports = {
  lang: 'English',
  home: "Home",
  blog: "Blog",
  blogs: "Blogs",
  search_place: "Search by Address, Block Height, TxHash…",
  price: "Price",
  height: "Block Height",
  block: "Block",
  blocks: "Blocks",
  transfers: "Transfers",
  extrinsics: "Extrinsics",
  extrinsic: "Extrinsic",
  view_all: "View all",
  txs: "Txs",
  address: "Address",
  type: "Type",
  amount: "Amount",
  blockHash: "Block Hash",
  blockTime: "Block Time",
  totalExtrinsics: "Total Extrinsics",
  status: "Status",
  transaction_information: "Transaction Information",
  noInformation: "No information",
  assets: "Assets",
  search_fail: "Search request failed",
  module: "Module",
  signedExtrinsics: "Signed Extrinsics",
  signature: "Signature",
  extrinsicsIndex: "Extrinsics Index",
  balance: "Total Balance",
  freeBalance: "Free Balance",
  reservedBalance: "Reserved Balance",
  created_block: "Created at block",
  updated_block: "Updated at block",
  nonce: "Nonce",
  destination: "Destination",
  activeAccount: "Active Account",
  accounts: "Accounts",
  active: "Active",
  projects: "Projects",
  from: 'From',
  to: 'To',
  event: 'Triggered Events',
  eventID: 'Event ID',
  copiedSuccess: "Copied Successfully",
  invalidAddress: "Invalid address",
  faucet: "Faucet",
  faucet_address: "Address",
  call: "Call",
  sender: "Sender",
  fee: "Fee",
  result: "Result",
  parameters: "Parameters",
  extrinsicHash: "Extrinsic Hash",
  moreInfo: "More Info",
  overview: "Overview",
  locked: "Locked",
  time: "Time",
  hash: "Hash",
  action: "Action",
  value: "Value",
  valuePrice: "Value",
  events: "Events",
  extrinsicID: "Extrinsic ID",
  EVMHash: "L2 Hash",
  GasLimit: "Gas Limit",
  GasTransaction: "Gas Used by Transaction",
  GasPrice: "Gas Price",
  InputData: "Input Data",
  searchResult: "Search Result",
  transactions: "Transactions",
  send: "Send",
  inputAddressFirst: "Input Address First",
  backToHome: "Back to Home",
  TotalSupply: "Total Supply",
  HoldersCount: "Holders Count",
  Holders: "Holders",
  SearchToken: "Search for Token Name or Symbol",
  Decimals: "Decimals",
  ContractCreator: "Contract Creator",
  TokenTracker: "Token Tracker",
  Contract: "Contract",
  Code: "Code",
  ReadContract: "Read Contract",
  WriteContract: "Write Contract",
  ContractVerified: "Contract Source Code Verified ",
  ExactMatch: "(Exact Match)",
  ContractName: "Contract Name",
  CompilerVersion: "Compiler Version",
  ContractSourceCode: "Contract Source Code",
  AreYouCreator: "Are you the contract creator?",
  VerifyPublishCode: "Verify and Publish your contract source code here",
  VerifyPublishTitle: "Verify & Publish Contract Source Code",
  EnterContractAddress: "Please enter the Contract Address you would like to verify",
  SelectCompilerType: "Please select Compiler Type",
  SelectCompilerVersion: "Please select Compiler Version",
  SelectLicenseType: "Please select Open Source License Type",
  EnterSolidityCode: "Enter the Solidity Contract Code below",
  VerifyPublish: "Verify & Publish",
  PleaseSelect: "Please Select",
  ContractABI: "Contract ABI",
  ReadContractInformation: "Read Contract Information",
  Pending: "Pending...",
  ConnectToWeb3: "Connect to Web3",
  ConnectedWeb3: "Connected - Web3",
  Query: "Query",
  Write: "Write",
  noMathExtension: "Please connect Math Wallet first",
  ContractCreationCode: "Contract Creation Code",
  totalCirculating: "Total Circulating",
  ofTotalSupply: "Percentage of total supply",
  unMined: "UnMined",
  TokenList: "Token List",
  CreateToken: "Create Token",
  TokenLogo: "Token Logo",
  TokenSymbol: "Token Symbol",
  TokenName: "Token Name",
  TotalSupply: "Total Supply",
  OwnerAddress:"Owner Address",
  Create: "Create",
  Swap: "Swap",
  Wallet: "Wallet",
  Copy: "Copy",
  ViewCode: "View Code",
  Decode: "Decode",
  SubmitSuccess: "Submitted successfully",
  CreateSuccess: "Created successfully",
  Error: "Error",
  Enter: "Please Enter",
  ConnectWallet: "Connect Wallet",
  Download: "Download",
  SubmitToken: "Submit Token",
  Mat20TokenTxns: "Mat20 Token Txns",
  FilterByHolder: "Filtered By Token Holder",
  ClearFilterAddress: "Clear Filter Address",
}