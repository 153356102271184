import Vue from 'vue'
import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router);

let routes = [
  {
    path: '*',
    component: () =>
      import ('@/components/404'),
  },
  {
    path: '/',
    component: () =>
      import ('@/components/home'),
  },
  {
    path: '/accounts',
    component: () =>
      import ('@/components/accounts')
  },
  {
    path: '/account/:address',
    component: () =>
      import ('@/components/account'),
    props: true
  },
  {
    path: '/evm/:address',
    component: () =>
      import ('@/components/evm/address'),
    props: true
  },
  {
    path: '/blocks',
    component: () =>
      import ('@/components/blocks')
  },
  {
    path: '/block/:height',
    component: () =>
      import ('@/components/block'),
    props: true
  },
  {
    path: '/extrinsic/:txID',
    component: () =>
      import ('@/components/extrinsic/index.vue'),
    props: true
  },
  {
    path: '/evmtx/:txID',
    component: () =>
      import ('@/components/evm/tx'),
    props: true
  },
  {
    path: '/transfers',
    component: () =>
      import ('@/components/transfers')
  },
  {
    path: '/faucet',
    component: () =>
      import ('@/components/faucet')
  },
  {
    path: '/searchResult/:hash',
    component: () =>
      import( '@/components/search-result' ),
      props: true
  },
  {
    path: '/createToken',
    component: () =>
      import ('@/components/create-token')
  },
  {
    path: '/tokens',
    component: () =>
      import ('@/components/tokens')
  },
  {
    path: '/token/:contract',
    component: () =>
      import ('@/components/token'),
    props: (route) => ( {
      a: route.query.a,
      contract:route.params.contract
    })
  },
  {
    path: '/verifyContract',
    component: () =>
      import ('@/components/verify-contract'),
    props: (route) => ( {
      contract: route.query.contract,
    })
  },
]
export default new Router({
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})