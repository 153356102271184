import Vue from 'vue'
import Vuex from 'vuex'
import i18n from 'static/js/i18n'

Vue.use( Vuex )

const state = {
  //要设置的全局访问的state对象，初始属性值
  lang: i18n.locale,
};
const getters = {
  //实时监听state值的变化(最新状态)
  lang(state) {
    return state.lang
  },
};
const mutations = {
  setLang(state, val) {
    i18n.setUserLanguage(val)
    state.lang = val;
  },
};
const actions = {
  setLang(context, val) {
    context.commit('setLang', val)
  },
};

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions
})
export default store