import { ApiPromise, WsProvider } from "@polkadot/api";

class user {
  constructor(wss, types = null) {
    let provider = new WsProvider(wss);
    this.api = ApiPromise.create({ provider, types });
  }

  async getApi() {
    return await this.api;
  }

  // 获取 EVM 账号
  async getEVMAccount(account) {
    const api = await this.api;
    return await api.query.accountService
      .multiAddressOf(account)
      .then((res) => {
        if (
          res.toHuman() &&
          res.toHuman().ethereum.Ethereum &&
          Number(res.toHuman().ethereum.Ethereum)
        ) {
          return res.toHuman();
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 获取 L1 账号
  async getL1Account(account) {
    const api = await this.api;
    return await api.query.accountService
      .fromEthereum({
        ethereum: account,
      })
      .then((res) => {
        if (Number(res.toHex()) && res.toHuman()) {
          return res.toHuman();
        }
        return null;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // 获取账户余额
  async getBalance(account) {
    const api = await this.api;
    return await api.derive.balances.all(account).then((res) => {
      return res.availableBalance.toString();
    });
  }

  async getAllBalance(account) {
    const api = await this.api;
    return await api.derive.balances.all(account);
  }
}
export default user;
